<template>
    <div class="page-wrapper" id="scroll_top">
        <section class="hero_content smaller" v-if="pageIsLoaded">
            <div class="data">
                <h2 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ content.subhero.surtitre }}
                </h2>
                <h1 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.subhero.titre"></span>
                </h1>
            </div>
            <div class="img" data-inview="revealRight" data-delay="200">
                <img :src="content.subhero.image.url" :alt="content.subhero.image.titre" />
            </div>
            <a href="#next" v-scroll-to:-120
                ><svg
                    fill="none"
                    class="arrow"
                    viewBox="0 0 13 26"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="m11.6412 17.9322-4.17083 4.2832v-22.2154h-1.94081v22.2154l-4.17082-4.2832-1.35874 1.395 6.5 6.6728 6.5-6.6728z"
                    /></svg
            ></a>
        </section>

        <section
            class="txt_row larg-padding-top small-padding-bottom"
            id="next"
            v-if="pageIsLoaded"
        >
            <div class="row" data-inview="fadeInUp" data-delay="200">
                <h4 class="title medium">{{ content.titreSimple }}</h4>
                <p class="regular-text">
                    <span v-html="content.texteSimple1"></span>
                </p>
            </div>
            <div class="row" data-inview="fadeInUp" data-delay="200">
                <h4 class="title medium">{{ $t('Avantages') }}</h4>
                <div class="wrap avantages">
                    <div class="icon" v-for="(item, i) in content.listeIcones" :key="i">
                        <img :src="item.image.url" :alt="item.image.titre" />
                        <p class="regular-text">
                            <span v-html="item.texte"></span>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <section class="postes_list" v-if="pageIsLoaded">
            <div class="postes_header">
                <h1 class="title medium">{{ $t('Nos postes disponibles') }}</h1>
            </div>
            <div class="postes_wrap">
                <div
                    class="poste"
                    data-inview="fadeInUp"
                    data-delay="200"
                    v-for="(item, i) in globals.postesEntry.data"
                    :key="i"
                >
                    <h3 class="title">{{ item.page.titre }}</h3>
                    <p class="regular-text" v-if="item.page.detailsDuPosteApi"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-building" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M3 21l18 0" />
                    <path d="M9 8l1 0" />
                    <path d="M9 12l1 0" />
                    <path d="M9 16l1 0" />
                    <path d="M14 8l1 0" />
                    <path d="M14 12l1 0" />
                    <path d="M14 16l1 0" />
                    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
                    </svg>{{ item.page.detailsDuPosteApi.facility_name }}</p>

                    <p class="regular-text" v-if="item.page.detailsDuPosteApi"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-map-pin" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="#000000" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                    <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                    </svg>{{ item.page.detailsDuPosteApi.facility_city }}</p>

                    <p class="regular-text" v-if="item.page.detailsDuPosteApi"><img :src="item.page.detailsDuPosteApi.facility_logo" alt=""></p>

                    <router-link :to="item.page.jsonUrl" class="cta">
                        <p class="regular-text">{{ $t('Postuler') }}</p>
                        <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                    </router-link>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'
// import Flickity from 'flickity'

export default {
    data() {
        return {
            lightboxelement: null,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.lightboxelement = GLightbox
                        GLightbox()

                        setTimeout(() => {
                            // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>

<style lang="scss" scoped></style>
